<template>
  <v-content>
    <v-sheet
      tile
      height="54"
      class="d-flex"
    >
    <v-container>
      <v-row
        justify="center"
        align-content="center"
      >
        <v-col
          align="center"
        >
          <v-btn
            icon

            @click="prevMonth()"
          >
            <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
          {{date}}
          <v-btn
            icon

            @click="nextMonth()"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    </v-sheet>
    <v-sheet height="600">
      <v-calendar
        locale="ja-JP"
        :day-format="timestamp => new Date(timestamp.date).getDate()"
        :month-format="timestamp => (new Date(timestamp.date).getMonth() + 1) + ' /'"
        ref="calendar"
        v-model="value"
        type="month"
        event-more-text="続きを表示する"
        :events="events"
        :start="startDate"
        :event-color="color"
        @click:more="viewDay"
        @change="getEvents"
        @click:event="showEvent"
      ></v-calendar>
        <v-dialog
            v-model="viewDayDialog"
            width="600px"
          >
            <v-calendar
              locale="ja-JP"
              ref="daycalendar"
              type="day"
              v-model="dayFocus"
              :events="dayEvents"
              :event-color="color"
              first-time="09-00"
              @change="getDayEvents"
              @click:event="showEvent"
            ></v-calendar>
          </v-dialog>
        <v-dialog
          v-model="selectedOpen"
          width="400px"
          :activator="selectedElement"
        >
          <v-card
            max-width="400px"
            flat
          >
            <v-card-title>
              <v-toolbar-title>
                ご予約入力
              </v-toolbar-title>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-container>
                <v-row
                  align="center"
                >
                  <v-col
                    cols="5"
                  >ご予約日：</v-col>
                  <v-col
                    class="ml-n8"
                  >{{reserveDay}}</v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="5"
                  >面談時間：</v-col>
                  <v-col
                    class="ml-n8"
                  >{{reserveTime}}</v-col>
                </v-row>
              </v-container>
              <v-form>
                <v-text-field
                  v-model="reserveSet.name"
                  label="お名前"
                  required
                />
                <v-text-field
                  v-model="reserveSet.reserverMailAddress"
                  :rules="emailRules"
                  label="メールアドレス"
                  required
                />
                <!-- this.selectedEvent.itemsのラジオボタンを表示 -->
                希望コース
                <v-radio-group v-model="reserveSet.item">
                  <template v-for="(item, index) in selectedEvent.items">
                    <v-radio
                      v-if="item.use"
                      :key="index"
                      :label="`${item.title} ${item.price}円`"
                      :value="item"
                      :mask="priceMask"
                    >
                    <template v-slot:label>
                      <v-card>
                        <v-card-title>
                          コース名：{{ item.title }}<br>
                          価格：{{ item.price }}円
                        </v-card-title>
                        <v-card-text style="white-space:pre-line">
                          {{ item.description }}
                        </v-card-text>
                      </v-card>
                    </template>
                  </v-radio>
                  </template>
                </v-radio-group>
                </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                :disabled="!valid"
                text
                color="secondary"
                @click="sendConfirmation"
              >
                確認画面へ
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
    </v-sheet>
  </v-content>
</template>

<script>
  import {AddReserveTemp,checkTemp,LoadApiData,loadItems} from '@/modules/OperationFirebase.js';
  import dayjs from 'dayjs';

  export default {
    props:['reserveList','reserveSet'],
    data(){
      return {
      focus: '',
      dayFocus:'',
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      events: [],
      dayEvents:[],
      value: '',
      valid: true,
      emailRules: [
        v => !!v || '',
        v => /.+@.+\..+/.test(v) || '',
      ],
      priceMask: '###,###,###,###',
      date: null,
      lineItems: [
        {
          price: 'price_1Jmx8vLx4LvvMlXiYMiWWmUB',//テスト
          // price: 'price_1JnMJFLx4LvvMlXir6C1Pjnn',//本番
          title: '新規の方',
          quantity: 1,
        },
        {
          price: 'price_1Jmx8vLx4LvvMlXiIIhIKM99', //テスト
          // price: 'price_1JnMJELx4LvvMlXiIsrVBAsK', //本番
          title: '2回目以降、ご紹介、学生の方',
          quantity: 1,
        }
      ],
      lineItem: null,
      viewDayDialog:false,
      color:"",
    }
    },
    methods: {
      getEvents(){
        this.events = this.reserveList;
        console.log('this.events',this.events);
      },
      getDayEvents(){
        const day = dayjs(this.dayFocus).format("M月D日");
        console.log('day',day);
        console.log('this.selectedElement',this.selectedElement)
        this.dayEvents = this.reserveList.map(event => {
          if(event.date === day){
            console.log(event)
            event.start = dayjs(event.start).format('YYYY-MM-DD HH:mm');
            event.end = dayjs(event.end).format('YYYY-MM-DD HH:mm');
            return event;
          }
        })
        this.dayEvents = this.dayEvents.filter( n => n != undefined)
        console.log('dayEvnets',this.dayEvents)
      },
      viewDay ({ date }) {
      console.log('viewDay',date)
      this.viewDayDialog = true;
      this.dayFocus = date;
    },
      showEvent({ nativeEvent,event }){ //カレンダーのイベント名をクリックしたときの関数
        // console.log('クリックしました');
          console.log('nativeEvent',nativeEvent,'event',event)
          console.log('this.reserveSet',this.reserveSet)
          event.items = event.items.map(item => {
            if(item.use){
              this.reserveSet.items.forEach(reserveSetItem => {
                if(item.itemId === reserveSetItem.itemId){
                  item.title = reserveSetItem.title;
                  item.price = parseFloat(reserveSetItem.price).toLocaleString();
                  item.description = reserveSetItem.description;
                }
              })
            }
            return item;
          })
          console.log('showEvent',event)
          const open = () => {
            this.selectedEvent = event
            console.log('this.selectedEvent',this.selectedEvent)
            this.selectedElement = nativeEvent.target
            this.selectedOpen = !this.selectedOpen;
            requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
          }
          if (this.selectedOpen) {
          this.selectedOpen = false
          requestAnimationFrame(() => requestAnimationFrame(() => open()))
        } else {
          open()
        }

        nativeEvent.stopPropagation()
      },
      requireCheck(){
        if(this.reserveSet.name && this.reserveSet.reserverMailAddress && this.reserveSet.item){
          return true;
        } else {
          alert('項目が埋まっていません');
          return false;
        }
      },
      async sendConfirmation(){
        console.log('sendConfirmation')
        if(!this.requireCheck()){return }
        console.log('reserveSet',this.reserveSet)
        const checktemp = await checkTemp(this.reserveSet,this.selectedEvent);
        if(checktemp){
          alert('大変恐れ入りますが、この時間帯の予約は埋まってしまいました。ページの再読み込みをお願い致します。')
          return ;
        }
        const success = await AddReserveTemp(this.reserveSet,this.selectedEvent);
        if(success){
          this.reserveSet.event = this.selectedEvent;
          this.reserveSet.reserveDate = dayjs(this.reserveSet.event.start).format('YYYY年M月D日HH時mm分～')+dayjs(this.reserveSet.event.end).format('HH時mm分');
          this.reserveSet.zoomStartTime = dayjs(this.reserveSet.event.start).format('YYYY-M-DTHH:mm:ss');
          console.log('this.reserveSet',this.reserveSet)
          this.$emit('send-confirmation',this.reserveSet)
          this.$emit('next-comp');
          localStorage.setItem('reserveSet',JSON.stringify(this.reserveSet))
        } else {
          alert('エラーが発生しました。恐れ入りますが、時間を置いて再度お試し下さい。')
        }
      },
      setDate(){
        let temp = this.$refs.calendar.title;
        console.log(typeof(temp),temp)
        temp = temp.split(' ');
        console.log('temp',temp)
        this.date = temp[1]+'年'+temp[0];
        console.log(this.date);
      },
      async nextMonth(){
        await this.$refs.calendar.next();
        this.setDate();
      },async prevMonth(){
        await this.$refs.calendar.prev();
        this.setDate();
      },
      async getColor(){
      const result = await LoadApiData(this.$route.query.uid);
      const keys = await result.data();
      console.log('keys',keys)
      console.log('color',keys.color)
      if(keys.color){
        console.log('color get',keys.color);
        this.color = keys.color.hex;
      } else {
        console.log('color notget',result);
        this.color = "#1976D2";
      }
    },
    async loadItemData(){
      const result = await loadItems(this.$route.query.uid);
      result.forEach((item) => {
          if(!item.delete){
              this.reserveSet.items.push(item)
          }
      })
      console.log('items',this.reserveSet.items)
    },
    },
    computed:{
      startDate(){
        return dayjs().format('YYYY-MM-DD');
      },
      reserveDay(){
        console.log('this.selectedEvent',this.selectedEvent)
        if(!this.selectedEvent.start){
          return '';
        }else {
          return dayjs(this.selectedEvent.start).format('YYYY年M月D日');
        }
      },
      reserveTime(){
        if(!this.selectedEvent.start){
          return '';
        } else {
          return dayjs(this.selectedEvent.start).format('HH時mm分～')+dayjs(this.selectedEvent.end).format('HH時mm分');
        }
      }
    },
    mounted(){
      console.log('calendarReserve this.reserveList',this.reserveList);
      console.log('calendar',this.$refs.calendar)
      this.setDate();
      this.getColor();
      this.loadItemData();
    }
  }
</script>